const questions = [
  // 1 Слайд \\
  {
    questionText:
      "Seorang pengemudi mendatangi kantor. Pengemudi tersebut meminta kenaikan harga. Apa tindakan Anda?",
    answerOptions: [
      {
        answerText:
          "Saya akan naikkan, pastinya. Biarkan pengemudi menghasilkan uang karena kami adalah kolega.",
        answerResult:
          "Kami paham. Tetapi, orang-orang akan lebih jarang bepergian dan pengemudi tidak akan menghasilkan lebih banyak uang. Mitra bisnis Maxim tahu segalanya tentang penetapan harga karena mereka menjalani pelatihan gratis.",
        result: false,
      },
      {
        answerText:
          "Saya akan jelaskan bahwa harga menciptakan keseimbangan antara uang yang dihasilkan pengemudi dan layanan yang terjangkau bagi pelanggan.",
        answerResult:
          "Cerdas! Alur pemikirannya sudah benar, meskipun cara kerjanya sedikit lebih rumit. Mitra bisnis Maxim tahu segalanya tentang penetapan harga karena mereka menjalani pelatihan gratis.",
        result: true,
      },
      {
        answerText:
          "Saya sibuk karena saya harus mengelola bisnis. Biarkan pengemudi menunggu saat saya lebih luang, saat itulah kami akan berbicara.",
        answerResult:
          "Pengemudi adalah mitra Anda dan mereka harus diperlakukan dengan layak. Jika tidak ada waktu, manajer bisa mengambil alih tugas berbicara dengan para pengemudi.",
        result: false,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд
  {
    questionText:
      "Oh, pelanggan mengeluhkan waktu tunggu kendaraan yang lama. Ada banyak order, perlu lebih banyak pengemudi. Bagaimana cara menarik pengemudi?",
    answerOptions: [
      {
        answerText:
          "Saya tahu di mana pengemudi biasanya berkumpul di kota saya. Cukup datangi saja tempat itu.",
        answerResult:
          "Kenapa tidak? Bisa saja cara ini berfungsi. Selain itu, jauh lebih efektif untuk memadukannya dengan pemasangan iklan. Maxim menghadiahkan paket periklanan kepada para mitra bisnisnya.",
        result: false,
      },
      {
        answerText:
          "Saya punya banyak teman pengemudi taksi. Saya akan minta mereka untuk menceritakan layanan ini kepada kenalan-kenalan mereka.",
        answerResult:
          "Opsi kerja. Akan ada lebih banyak pengemudi yang bisa Anda tarik kalau Anda memasang iklan. Maxim menghadiahkan seperangkat materi periklanan siap pakai kepada para mitra bisnisnya.",
        result: false,
      },
      {
        answerText:
          "Saya akan pasang iklan, pastinya. Ini cara yang paling efektif.",
        answerResult:
          "Dan tidak perlu mengeluarkan banyak uang: mitra-mitra bisnis layanan Maxim mendapatkan hadiah berupa paket periklanan. Hanya perlu memasang materi periklanan tersebut.",
        result: true,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд
  {
    questionText:
      "Pengemudi menulis ke “Dukungan” bahwa mereka ingin tahu tentang pembelian seragam. Pertanyaan ini tidak mendesak. Apa yang akan kita lakukan?",
    answerOptions: [
      {
        answerText:
          "Manajer di kantor yang akan menangani hal ini. Tentu saja, akan ada banyak uang yang dihabiskan untuk membayar gaji, tetapi tidak boleh membiarkan pengaduan seperti ini begitu saja tanpa memberikan respons.",
        answerResult:
          "Kita tahu cara menghemat uang untuk memproses ulasan: menjadi mitra bisnis Maxim. Pertanyaan-pertanyaan dari klien akan dibereskan oleh para spesialis dari kantor jarak jauh.",
        result: true,
      },
      {
        answerText:
          "Saya sendiri yang akan menghubungi orang tersebut saat agak lebih luang. Kita akan membereskan masalah ini.",
        answerResult:
          "Terima kasih! 💛 Tetapi, biar bagaimanapun Anda sendiri saja tidak cukup, lebih baik mendelegasikan persoalan ini. Bagi mitra bisnis Maxim, pengaduan dari pelanggan akan dijawab oleh para spesialis dari kantor jarak jauh.",
        result: false,
      },
      {
        answerText: "Tidak lakukan apa-apa. Saya punya banyak urusan lain.",
        answerResult:
          "Orang-orang akan selalu menghubungi layanan dan mereka semua harus diberikan respons. Jika tidak, pelanggan dan pengemudi pasti kabur. Maxim menghubungkan mitra bisnis dengan kantor jarak jauh. Para spesialislah yang akan mengambil alih percakapan dengan para pengguna.",
        result: false,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд
  {
    questionText:
      "Peladen milik pesaing mengalami kerusakan dan aplikasi berhenti menerima order. Bagaimana cara untuk menghindari situasi seperti ini di pihak Anda?",
    answerOptions: [
      {
        answerText:
          "Tidak ada cara apa pun. Semua aplikasi mengalami gangguan dari waktu ke waktu, terutama ketika bebannya berat.",
        answerResult:
          "Kemungkinan terjadinya gangguan bisa diminimalkan oleh dukungan teknis. Kinerja aplikasi milik mitra bisnis Maxim biasanya dikontrol sepanjang hari.",
        result: false,
      },
      {
        answerText: "Apa lagi ini?! Apakah juga harus merekrut ahli TI?!",
        answerResult:
          "Tidak perlu kalau Anda adalah mitra bisnis layanan Maxim: mereka biasanya memanfaatkan dukungan teknis gratis sepanjang hari.",
        result: false,
      },
      {
        answerText:
          "Saya sudah mempertimbangkan semuanya: pengembang aplikasi menyediakan dukungan teknis.",
        answerResult:
          "Cerdas! Maxim menyediakan ketentuan yang serupa kepada para mitra bisnisnya: dukungan teknis gratis 24/7.",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд
  {
    questionText:
      "Bulan pun berakhir. Penghasilan bagus, tetapi ini bukan ambang batasnya. Bagaimana cara memahami ke arah mana Anda bergerak?",
    answerOptions: [
      {
        answerText:
          "Apa bedanya? Cukup berusaha untuk bekerja lebih baik saja.",
        answerResult:
          "Bagaimana caranya supaya tahu kalau kita menjadi lebih baik atau lebih buruk? Statistik diperlukan dalam hal ini. Mitra bisnis layanan Maxim akan memperoleh perangkat lunak untuk pemimpin dan mereka bisa membuat laporan apa pun hanya dalam beberapa menit.",
        result: false,
      },
      {
        answerText:
          "Saya akan mempelajari program khusus, menyusun laporan, dan membandingkan indikatornya.",
        answerResult:
          "Kita tahu cara untuk menghemat banyak waktu. Mitra bisnis layanan Maxim akan memperoleh perangkat lunak untuk pemimpin. Laporan untuk periode apa pun dapat disusun secara otomatis.",
        result: true,
      },
      {
        answerText:
          "Semuanya sudah jelas: harus memotivasi pengemudi untuk bekerja lebih banyak.",
        answerResult:
          "Atau mungkin menarik lebih banyak klien? Statistik diperlukan dalam hal ini. Mitra bisnis layanan Maxim akan memperoleh perangkat lunak untuk pemimpin dan laporan jenis apa pun akan dapat disusun hanya dalam beberapa menit.",
        result: false,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
